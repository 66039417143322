
import VueCookies from 'vue-cookies';
import axios from 'axios'
import db from '@/plugins/db';

const isAuthenticated = () => {
  return VueCookies.isKey('token');
};

const initialSync = async () => {
    try{
        const token = VueCookies.get('token');
        const test_server = VueCookies.get('test_server');
        const http = axios.create({
            baseURL: "/stocktake/",  
            headers: {
              "Content-type": "application/json;charset=utf-8" ,
              "Authorization": `Basic ${token}`,
              "X-SHEIKE-TEST": test_server
            }  
          });
  
        const last_product = await db.items.orderBy("created").reverse().limit(1).toArray();
        var last_product_created = "";
        if(last_product.length == 1) {
            last_product_created = last_product[0].created.toISOString();
        }
        
        var response = await http.get(`/get-warehouses`);
        db.transaction("rw", db.warehouses, () => {
            if(response && response.data) {
                response.data.forEach(wh => {
                        db.warehouses.put({warehouse: wh.warehouse, name: wh.name});
                })
            }
        });

        response = await http.get(`/get-products?created=${last_product_created}`);

        db.transaction("rw", db.items, () => {
            if(response && response.data) {
                response.data.forEach(item => {
                        db.items.put({sku: item.sku, 
                            style: item.style,
                            colour: item.colour,
                            size: item.size,
                            description: item.description, 
                            barcode: item.barcode,
                            created: new Date(item.created_date),
                        });
                })
            }
        })
        window.$initial_sync_done = true;
    }catch(e){
        console.log(e);
    }
}

const requireAuth = (to, from, next) => {
  if (isAuthenticated()) {
    if(!window.initialSync) initialSync();
    next();
  } else {
    next('/sign-in');
  }
};

export { isAuthenticated, requireAuth };