import { createRouter, createWebHistory } from 'vue-router'
import { requireAuth } from '../auth';
import NProgress from "nprogress";

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: requireAuth,
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('../views/LoginView.vue'),
  },
  {
    beforeEnter: requireAuth, 
    path: '/new-count',
    name: 'new-count',
    component: () => import('../views/NewCountView.vue')
  },
  {
    beforeEnter: requireAuth, 
    path: "/scan/:id/",
    component: () => import("../views/ScanView.vue")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    NProgress.start();
    NProgress.set(0.1);
  }
  next();
});
 
router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    NProgress.start();
    NProgress.set(0.1);
  }
  next();
});
 
router.afterEach(() => {
  // Complete the animation of the route progress bar.
  setTimeout(() => NProgress.done(), 500);
});
export default router
