import Dexie from 'dexie'
 
const db = new Dexie('sheike');
const db_schema = {
    items: '&sku,style,description,barcode,created',
    stocktakes: '++id',
    warehouses: '&warehouse,name'
}
 
const db_version = 5
 
db.version(db_version).stores(db_schema)
 
db.open().catch(console.error);
 
export default db;