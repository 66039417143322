import { createApp, reactive } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "@/scss/style.scss";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCamera, faHome, faSignOutAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
library.add( faHome, faDownload, faPlus, faSignOutAlt, faCamera, faTrash);
import VueCookies from 'vue-cookies';

const app = createApp(App)
app.use(VueCookies, { expires: '30d'});
app.use(router);
app.use(reactive);
app.component("font-awesome-icon", FontAwesomeIcon);
app.directive('focus', {
    // When the bound element is mounted into the DOM...
    mounted(el) {
      // Focus the element
      el.focus()
} });

app.mount('#app');
