import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (registration) {
      console.log('Service worker is active.')
    },
    registered (registration) {
      console.log('Service worker has been registered.')
      setInterval(() => {
        registration.update()
      }, 300000)
    },
    cached (registration) {
      console.log('Content has been cached for offline use.')
    },
    updatefound (registration) {
      console.log('New content is downloading.')
    },
    updated (registration) {
      const waitingServiceWorker = registration.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', event => {
          console.log('New content is available; please refresh.')
          //window.location.reload(false);
        });
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
